import { TProduct } from 'types/product';
import { axiosClient } from '../axiosClient';
import queryString from 'query-string';
import { TOptionsQuery } from 'types/common';

const productApi = {
  getProducts: (params: TOptionsQuery<TProduct>) => {
    const url = '/products';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  createProduct: (data: TProduct) => {
    const url = '/products';
    return axiosClient.post(url, data);
  },
  updateProduct: (data: TProduct) => {
    const { id, ...rest } = data;
    const url = `/products/${id}`;
    return axiosClient.put(url, rest);
  },
  deleteProduct: (id: string) => {
    const url = `/products/${id}`;
    return axiosClient.delete(url);
  },
};

export default productApi;
