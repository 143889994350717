import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import productApi from 'app/axios/api/productApi';
import { TProduct } from 'types/product';
import { TOptionsQuery, TPagination, TResponsePagination } from 'types/common';
import { defaultPagination, DEFAULT_PAGE } from 'app/helpers/common';
import { setErrorMessage, setSuccessMessage } from './messageSlice';
import { MESSAGE_PRODUCT } from 'app/helpers/notifications/message';

export type ProductState = {
  products: TProduct[];
  loading: boolean;
  pagination: TPagination;
  newData: TProduct[];
};

const initialState: ProductState = {
  loading: false,
  products: [],
  pagination: defaultPagination,
  newData: [],
};

export const DEFAULT_SORT_PRODUCTS = 'createdAt:desc';
export const DEFAULT_LIMIT_PRODUCTS = 12;

export const getProducts = createAsyncThunk(
  'auth/getProducts',
  async (params: TOptionsQuery<TProduct>, thunkAPI) => {
    thunkAPI.dispatch(setLoadingProduct(true));
    const {
      sortBy = DEFAULT_SORT_PRODUCTS,
      limit = DEFAULT_LIMIT_PRODUCTS,
      page = DEFAULT_PAGE,
    } = params ? params : {};

    let newParams: TOptionsQuery<TProduct> = Object.create(null);
    newParams.sortBy = sortBy;
    newParams.limit = limit;
    newParams.page = page;
    const response = await productApi.getProducts({ ...params, ...newParams });
    thunkAPI.dispatch(setLoadingProduct(false));
    return response.data as TResponsePagination<TProduct[]>;
  },
);

export const createProduct = createAsyncThunk(
  'auth/createProduct',
  async (data: TProduct, thunkAPI) => {
    thunkAPI.dispatch(setLoadingProduct(true));
    await productApi
      .createProduct(data)
      .then(() => {
        thunkAPI.dispatch(setSuccessMessage(MESSAGE_PRODUCT.createSuccess));
      })
      .catch((err) => {
        thunkAPI.dispatch(
          setErrorMessage(err?.response?.data?.message || MESSAGE_PRODUCT.createError),
        );
      });
    thunkAPI.dispatch(setLoadingProduct(false));
  },
);

export const updateProduct = createAsyncThunk(
  'auth/updateProduct',
  async (data: TProduct, thunkAPI) => {
    thunkAPI.dispatch(setLoadingProduct(true));
    await productApi
      .updateProduct(data)
      .then((res) => {
        thunkAPI.dispatch(setProducts(res.data));
      })
      .catch((err) => {});
    thunkAPI.dispatch(setLoadingProduct(false));
  },
);

export const deleteProductAction = createAsyncThunk(
  'auth/deleteProductAction',
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setLoadingProduct(true));
    await productApi
      .deleteProduct(id)
      .then(() => {
        thunkAPI.dispatch(deleteProduct(id));
      })
      .catch((err) => {});
    thunkAPI.dispatch(setLoadingProduct(false));
  },
);

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setLoadingProduct(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setProducts(state, action: PayloadAction<TProduct>) {
      state.products = state.products.map((product) => {
        return product.id === action.payload.id ? action.payload : product;
      });
    },
    deleteProduct(state, action: PayloadAction<string>) {
      state.products = state.products.filter((product) => product.id !== action.payload);
    },
    clearDataProduct(state) {
      state.products = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state: ProductState) => {
      state.loading = true;
    });
    builder.addCase(getProducts.rejected, (state: ProductState) => {
      state.loading = false;
    });
    builder.addCase(
      getProducts.fulfilled,
      (state: ProductState, action: PayloadAction<TResponsePagination<TProduct[]>>) => {
        const { results, ...pagination } = action.payload;
        state.loading = false;
        state.products = [...state.products, ...action.payload.results];
        state.newData = action.payload.results;
        state.pagination = pagination;
      },
    );
  },
});

export const { actions, reducer: productReducer } = slice;
export const { setLoadingProduct, setProducts, deleteProduct, clearDataProduct } = actions;
export default productReducer;
