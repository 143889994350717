import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { TOptionsQuery, TPagination, TResponsePagination } from 'types/common';
import dashboardApi from 'app/axios/api/dashboardApi';
import { TDashboard } from 'types/dashboard';
import { defaultPagination } from 'app/helpers/common';

export type DashboardState = {
  dashboards: TDashboard;
  loadingDashBoard: boolean;
};

const initialState: DashboardState = {
  loadingDashBoard: false,
  dashboards: {} as TDashboard,
};

export const getDashboardInformation = createAsyncThunk(
  'auth/getDashboards',
  async (params, thunkAPI) => {
    thunkAPI.dispatch(setLoadingDashboard(true));
    const response = await dashboardApi.getDashboards();
    thunkAPI.dispatch(setLoadingDashboard(false));
    thunkAPI.dispatch(setDashboards(response.data));
    return response.data as TOptionsQuery<TDashboard>;
  },
);

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoadingDashboard(state, action: PayloadAction<boolean>) {
      state.loadingDashBoard = action.payload;
    },
    setDashboards(state, action: PayloadAction<TDashboard>) {
      state.dashboards = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardInformation.pending, (state: DashboardState) => {
      state.loadingDashBoard = true;
    });
    builder.addCase(getDashboardInformation.rejected, (state: DashboardState) => {
      state.loadingDashBoard = false;
    });

    builder.addCase(
      getDashboardInformation.fulfilled,
      (state: DashboardState, action: PayloadAction<TDashboard>) => {
        state.loadingDashBoard = false;
        state.dashboards = action.payload;
      },
    );
  },
});

export const { actions, reducer: dashboardReducer } = slice;
export const { setLoadingDashboard, setDashboards } = actions;
export default dashboardReducer;
