import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { TPagination, TResponsePagination } from 'types/common';
import { defaultPagination } from 'app/helpers/common';
import { setErrorMessage, setSuccessMessage } from './messageSlice';
import {
  MESSAGE_EXCHANGE_PRODUCT,
  MESSAGE_PRODUCT,
  MESSAGE_UPLOAD_IMAGE,
} from 'app/helpers/notifications/message';
import { TExProduct } from 'types/exProduct';
import exProductApi from 'app/axios/api/exchangeProductApi';

export type ExProductState = {
  exProducts: TExProduct[];
  loadingEx: boolean;
  pagination: TPagination;
};

const initialState: ExProductState = {
  loadingEx: false,
  exProducts: [],
  pagination: defaultPagination,
};

export const getExProducts = createAsyncThunk('auth/getProducts', async (params, thunkAPI) => {
  const response = await exProductApi.getExProducts();
  return response.data as TResponsePagination<TExProduct[]>;
});

export const createExProduct = createAsyncThunk(
  'auth/createProduct',
  async (data: TExProduct, thunkAPI) => {
    thunkAPI.dispatch(setLoadingExProduct(true));
    await exProductApi
      .createExProduct(data)
      .then((response) => {
        thunkAPI.dispatch(getExProducts());
        const { id, name } = response.data;
        exProductApi.getSignedUrl({ id, name, type: data.type }).then((res) => {
          exProductApi
            .uploadImgExProduct(res.data, data)
            .then(() => {
              thunkAPI.dispatch(setSuccessMessage(MESSAGE_EXCHANGE_PRODUCT.createSuccess));
              exProductApi.getExProductAfterUploadImg(response.data);
            })
            .catch((err) => {
              thunkAPI.dispatch(
                setErrorMessage(err?.response?.data?.message || MESSAGE_UPLOAD_IMAGE.uploadFail),
              );
            });
        });
      })
      .catch((err) => {
        thunkAPI.dispatch(
          setErrorMessage(err?.response?.data?.message || MESSAGE_EXCHANGE_PRODUCT.createError),
        );
      });
    thunkAPI.dispatch(setLoadingExProduct(false));
  },
);

export const updateExProduct = createAsyncThunk(
  'auth/updateProduct',
  async (data: TExProduct, thunkAPI) => {
    thunkAPI.dispatch(setLoadingExProduct(true));
    await exProductApi
      .updateExProduct(data)
      .then((res) => {
        thunkAPI.dispatch(setExProducts(res.data));
      })
      .catch((err) => {});
    thunkAPI.dispatch(setLoadingExProduct(false));
  },
);

export const deleteExProductAction = createAsyncThunk(
  'auth/deleteProductAction',
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setLoadingExProduct(true));
    await exProductApi
      .deleteExProduct(id)
      .then(() => {
        thunkAPI.dispatch(deleteExProduct(id));
      })
      .catch((err) => {});
    thunkAPI.dispatch(setLoadingExProduct(false));
  },
);

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setLoadingExProduct(state, action: PayloadAction<boolean>) {
      state.loadingEx = action.payload;
    },
    setExProducts(state, action: PayloadAction<TExProduct>) {
      state.exProducts = state.exProducts.map((product) =>
        product.id === action.payload.id ? action.payload : product,
      );
    },
    deleteExProduct(state, action: PayloadAction<string>) {
      state.exProducts = state.exProducts.filter((exProduct) => exProduct.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExProducts.pending, (state: ExProductState) => {
      state.loadingEx = true;
    });
    builder.addCase(getExProducts.rejected, (state: ExProductState) => {
      state.loadingEx = false;
    });
    builder.addCase(
      getExProducts.fulfilled,
      (state: ExProductState, action: PayloadAction<TResponsePagination<TExProduct[]>>) => {
        const { results, ...pagination } = action.payload;
        state.loadingEx = false;
        state.exProducts = action.payload.results;
        state.pagination = pagination;
      },
    );
  },
});

export const { actions, reducer: exProductReducer } = slice;
export const { setLoadingExProduct, setExProducts, deleteExProduct } = actions;
export default exProductReducer;
