import { axiosClient } from '../axiosClient';

const dashboardApi = {
  getDashboards: () => {
    const url = '/dashboards';
    return axiosClient.get(url);
  },
};

export default dashboardApi;
