import { TProduct } from 'types/product';
import { axiosClient } from '../axiosClient';
import { TQRCode } from 'types/qrcode';

const qrCodeApi = {
  getQRCodes: () => {
    const url = '/qrcodes';
    return axiosClient.get(url);
  },
  createQRCode: (data: TQRCode) => {
    const url = '/qrcodes';
    return axiosClient.post(url, data);
  },
  updateQRCode: (data: TQRCode) => {
    const { id, ...rest } = data;
    const url = `/qrcodes/${id}`;
    return axiosClient.put(url, rest);
  },
  deleteQRCode: (id: string) => {
    const url = `/qrcodes/${id}`;
    return axiosClient.delete(url);
  },
};

export default qrCodeApi;
