import { axiosClient } from '../axiosClient';

const adminApi = {
  getCurrentUser: () => {
    const url = 'users/me';
    return axiosClient.get(url);
  },
};

export default adminApi;
