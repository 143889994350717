import React, { ReactNode } from 'react';
import HeaderAdmin from '../Common/HeaderAdmin/HeaderAdmin';
import NavAdmin from '../Common/NavAdmin/NavAdmin';
import s from './style.module.scss';

export type PropsLayout = {
  children: ReactNode;
};
function Layout({ children }: PropsLayout) {
  return (
    <div className="layout">
      <HeaderAdmin />
      <div className={s.content}>
        <NavAdmin />
        <div className={s.wrapper}>{children}</div>
      </div>
    </div>
  );
}

export default Layout;
