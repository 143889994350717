export const Epath = {
  // NotFoundPage
  notFoundPage: '/notfound',

  // AdminLoginPage
  adminLoginPage: '/login',

  // AdminTopPage
  adminTopPage: '/',

  // AdminTopPage
  qrCodeScreen: '/qr-code',

  // Point Registration
  pointRegistration: '/point-reg',

  // Check All Status
  exchangeProduct: '/ex-product',

  // RegisterPage
  registerPage: '/register',

  // Register Confirm Page
  registerConfirmPage: '/register-confirm',
};
