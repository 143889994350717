import { TProduct } from 'types/product';
import { axiosClient } from '../axiosClient';
import { TExProduct } from 'types/exProduct';
import queryString from 'query-string';
import axios from 'axios';

const exProductApi = {
  getExProducts: () => {
    const url = '/exchange-products';
    return axiosClient.get(url);
  },
  createExProduct: (data: TExProduct) => {
    const { type, file, ...rest } = data;
    const url = '/exchange-products';
    return axiosClient.post(url, rest);
  },
  getSignedUrl: (data: TExProduct) => {
    const { id, ...rest } = data;
    const url = `/exchange-products/get-signed-url/${id}`;
    const query = queryString.stringify(rest);
    return axiosClient.get(`${url}?${query}`);
  },
  uploadImgExProduct: (url: string, data: TExProduct) => {
    const { file, type } = data;
    return axios.put(url, file, { headers: { 'Content-Type': type } });
  },
  getExProductAfterUploadImg: (data: TExProduct) => {
    const { id, name } = data;
    const url = `/exchange-products/update-image/${id}`;
    const query = queryString.stringify({ name });
    return axiosClient.get(`${url}?${query}`);
  },
  updateExProduct: (data: TExProduct) => {
    const { id, ...rest } = data;
    const url = `/exchange-products/${id}`;
    return axiosClient.put(url, rest);
  },
  deleteExProduct: (id: string) => {
    const url = `/exchange-products/${id}`;
    return axiosClient.delete(url);
  },
};

export default exProductApi;
