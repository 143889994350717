import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message as messageAntd } from 'antd';
import { RootState } from 'types/RootState';
import { resetMessage } from 'store/slice/messageSlice';

const MessageNotification = () => {
  const dispatch = useDispatch();
  const { error, success, message } = useSelector((state: RootState) => state.message);
  const resetState = () => dispatch(resetMessage());

  useEffect(() => {
    if (success && message) {
      messageAntd.success(message);
      resetState();
    }
  }, [success, message]);

  useEffect(() => {
    if (error && message) {
      messageAntd.error(message);
      resetState();
    }
  }, [error, message]);
  return <></>;
};

export default MessageNotification;
