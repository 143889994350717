import { TSignInRequest } from 'app/pages/AuthPage/LoginPage/slice/authTypes';
import { axiosClient } from '../axiosClient';

const authApi = {
  login: (data: TSignInRequest) => {
    const url = 'auth/login';
    return axiosClient.post(url, data);
  },
  logout: (refreshToken: string) => {
    const url = 'auth/logout';
    return axiosClient.post(url, { refreshToken });
  },
};

export default authApi;
