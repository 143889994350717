import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MESSAGE_ERROR_DEFAULT, MESSAGE_SUCCESS_DEFAULT } from 'app/helpers/notifications/message';

const initialState = {
  success: false,
  error: false,
  message: '',
};

export type MessageState = typeof initialState;

const slice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setSuccessMessage(state: MessageState, action: PayloadAction<string>) {
      return {
        error: false,
        success: true,
        message: action.payload ? action.payload : MESSAGE_SUCCESS_DEFAULT,
      };
    },
    setErrorMessage(state: MessageState, action: PayloadAction<string>) {
      return {
        success: false,
        error: true,
        message: action.payload ? action.payload : MESSAGE_ERROR_DEFAULT,
      };
    },
    resetMessage() {
      return initialState;
    },
  },
});

export const { actions, reducer: messageReducer } = slice;
export const { setSuccessMessage, setErrorMessage, resetMessage } = actions;
export default messageReducer;
