export const DEFAULT_TIP_SPIN = '読み込み中';
export const MESSAGE_ERROR_DEFAULT = 'エラー！変更内容がまだ保存されていません。';
export const MESSAGE_SUCCESS_DEFAULT = '変更内容が保存されました。';

// Auth
export const MESSAGE_LOGIN_SUCCESS = 'ようこそ!';
export const MESSAGE_LOGIN_ERROR = 'ログインに失敗しました。';
export const MESSAGE_LOGOUT_SUCCESS = 'ログアウトしました。';
export const MESSAGE_CHANGE_PASSWORD_SUCCESS = 'パスワード変更が完了しました。';
export const MESSAGE_INCORRECT_EMAIL_OR_PASSWORD =
  'メールアドレスまたはパスワードが正しくありません。';

// Product
export const MESSAGE_PRODUCT = {
  createSuccess: '商品が作成されました。',
  createError: '商品が作成されていません。',
  notAuthorized: 'あなたはログアウトしています',
};

export const MESSAGE_EXCHANGE_PRODUCT = {
  createSuccess: '交換商品が作成されました。',
  createError: '交換商品が作成されていません。',
};

export const MESSAGE_UPLOAD_IMAGE = {
  uploadSuccess: 'アップロード成功',
  uploadFail: 'アップロード失敗',
};

// QRCode
export const MESSAGE_QRCODE = {
  createSuccess: 'QRコードが作成されました。',
  createError: 'QRコードが作成されていません。',
};

export const MESSAGE_CHOOSE_IMAGE = {
  mustChooseImage: '交換商品を作成するには、写真を選択する必要があります。',
  chooseImageSuccess: '画像の選択が成功しました',
  chooseImageError: '適切な画像を選択してください',
};
