import React, { useEffect, useState } from 'react';
import s from './style.module.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Epath } from 'app/routes/routesConfig';
import { logOut } from 'app/pages/AuthPage/LoginPage/slice/authSlice';
import { useDispatch } from 'react-redux';
import c from 'clsx';
import authApi from 'app/axios/api/authApi';
import { setSuccessMessage } from 'store/slice/messageSlice';
import { MESSAGE_LOGOUT_SUCCESS } from 'app/helpers/notifications/message';
import { getRefreshTokenFromLocalStorage } from 'app/helpers/localStorage';
import {
  BarChartOutlined,
  QrcodeOutlined,
  ControlOutlined,
  InboxOutlined,
  SwapOutlined,
  LogoutOutlined,
  DeliveredProcedureOutlined,
} from '@ant-design/icons';
import { clearDataProduct } from 'store/slice/productSlice';

const NavAdmin = () => {
  const listItem = [
    'ステータス確認',
    'ポイント登録',
    '交換商品登録',
    'QRコード作成',
    'QRコード配布',
    '精算処理',
  ];
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== Epath.qrCodeScreen) {
      dispatch(clearDataProduct());
    }
  }, [location.pathname]);

  const logOutHandle = () => {
    const refreshToken = getRefreshTokenFromLocalStorage();
    dispatch(logOut());
    authApi.logout(refreshToken);
    dispatch(setSuccessMessage(MESSAGE_LOGOUT_SUCCESS));
    history.push(Epath.adminLoginPage);
  };

  return (
    <div className={s.container}>
      <div className={s.title}>
        <span>メニュー</span>
      </div>
      <ul className={s.list}>
        <Link to={Epath.adminTopPage}>
          <li className={s.item}>
            <span className={location.pathname === Epath.adminTopPage ? s.selected : ''}>
              <BarChartOutlined className={s.icon} /> <p>{listItem[0]}</p>
            </span>
          </li>
        </Link>
        <Link to={Epath.pointRegistration}>
          <li className={s.item}>
            <span className={location.pathname === Epath.pointRegistration ? s.selected : ''}>
              <InboxOutlined className={s.icon} /> <p>{listItem[1]}</p>
            </span>
          </li>
        </Link>
        <Link to={Epath.exchangeProduct}>
          <li className={s.item}>
            <span className={location.pathname === Epath.exchangeProduct ? s.selected : ''}>
              <SwapOutlined className={s.icon} /> <p>{listItem[2]}</p>
            </span>
          </li>
        </Link>
        <Link to={Epath.qrCodeScreen}>
          <li className={s.item}>
            <span className={location.pathname === Epath.qrCodeScreen ? c(s.selected) : ''}>
              <QrcodeOutlined className={s.icon} /> <p>{listItem[3]}</p>
            </span>
          </li>
        </Link>
        <li className={s.item}>
          <span aria-disabled={true}>
            <DeliveredProcedureOutlined className={s.icon} /> <p>{listItem[4]}</p>
          </span>
        </li>
        <li className={s.item}>
          <span aria-disabled={true}>
            <ControlOutlined className={s.icon} /> <p>{listItem[5]}</p>
          </span>
        </li>
      </ul>
      <div className={s.logout} onClick={logOutHandle}>
        <LogoutOutlined size={24} className={s.icon} />
        <span>ログアウト</span>
      </div>
    </div>
  );
};

export default NavAdmin;
