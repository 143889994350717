import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  getLanguage,
  getRefreshTokenFromLocalStorage,
  getTokenFromLocalStorage,
  getUserFromLocalStorage,
  saveLanguage,
} from './helpers/localStorage';
import RenderRoutes, { routes } from './routes/routes';
import 'antd/dist/antd.min.css';
import './styles/variables.scss';
import './styles/base.scss';
import './styles/elements.scss';
import './styles/typography.scss';
import './styles/dependencies/index.scss';
import { RootState } from 'types/RootState';
import { isEmptyObject } from './helpers/common';
import { getCurrentUser, setAuth } from './pages/AuthPage/LoginPage/slice/authSlice';
import MessageNotification from './components/Common/MessageNotification/MessageNotification';

// Set locate to moment lib
const language = getLanguage();
saveLanguage(language);
moment.locale(language);

export function App() {
  const dispatch = useDispatch();
  const { authenticated, currentUser } = useSelector((state: RootState) => state.auth);
  const isAuthenticated = !!(authenticated && currentUser && Object.keys(currentUser).length);

  const localUser = getUserFromLocalStorage();
  const localToken = getTokenFromLocalStorage();
  const localRefreshToken = getRefreshTokenFromLocalStorage();
  const checkAuthLocal = !!(
    localToken &&
    localRefreshToken &&
    localUser &&
    !isEmptyObject(localUser)
  );

  useEffect(() => {
    if (checkAuthLocal) {
      dispatch(setAuth());
    }
  }, [checkAuthLocal, dispatch]);

  useEffect(() => {
    authenticated && checkAuthLocal && dispatch(getCurrentUser());
  }, [authenticated, checkAuthLocal, dispatch]);

  return (
    <>
      <Router basename="">
        <RenderRoutes routes={routes} isAuthenticated={isAuthenticated || checkAuthLocal} />
      </Router>
      <MessageNotification />
    </>
  );
}
