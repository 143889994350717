import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { TPagination, TResponsePagination } from 'types/common';
import { defaultPagination } from 'app/helpers/common';
import { setErrorMessage, setSuccessMessage } from './messageSlice';
import { MESSAGE_QRCODE } from 'app/helpers/notifications/message';
import { TQRCode } from 'types/qrcode';
import qrCodeApi from 'app/axios/api/qrcodeApi';
import { downloadBlob, getCurrentTime } from 'app/helpers/functions';

export type QRCodeState = {
  qrcodes: TQRCode[];
  loadingQRCode: boolean;
  csvData: string;
  pagination: TPagination;
};

const initialState: QRCodeState = {
  loadingQRCode: false,
  qrcodes: [],
  csvData: '',
  pagination: defaultPagination,
};

export const getQRCodes = createAsyncThunk('auth/getQRcodes', async (params, thunkAPI) => {
  const response = await qrCodeApi.getQRCodes();
  return response.data as TResponsePagination<TQRCode[]>;
});

export const createQRCode = createAsyncThunk(
  'auth/createQRCode',
  async (data: TQRCode, thunkAPI) => {
    thunkAPI.dispatch(setLoadingQRCodes(true));
    const { productName, ...rest } = data;
    await qrCodeApi
      .createQRCode(rest)
      .then((res) => {
        const str = res.data as string;
        const csvBlob = new Blob([str]);
        downloadBlob(csvBlob, `${productName}_${data.issuedNumber}_${getCurrentTime()}.csv`);
        thunkAPI.dispatch(setCsv(str.replaceAll(/["']/g, '')));
        thunkAPI.dispatch(setSuccessMessage(MESSAGE_QRCODE.createSuccess));
      })
      .catch((err) => {
        thunkAPI.dispatch(
          setErrorMessage(err?.response?.data?.message || MESSAGE_QRCODE.createError),
        );
      });
    thunkAPI.dispatch(setLoadingQRCodes(false));
  },
);

export const updateQRCode = createAsyncThunk(
  'auth/updateQRCode',
  async (data: TQRCode, thunkAPI) => {
    thunkAPI.dispatch(setLoadingQRCodes(true));
    await qrCodeApi
      .updateQRCode(data)
      .then((res) => {
        thunkAPI.dispatch(setQRCodes(res.data));
      })
      .catch((err) => {});
    thunkAPI.dispatch(setLoadingQRCodes(false));
  },
);

export const deleteQRCodeAction = createAsyncThunk(
  'auth/deleteQRCodeAction',
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setLoadingQRCodes(true));
    await qrCodeApi
      .deleteQRCode(id)
      .then(() => {
        thunkAPI.dispatch(deleteQRCode(id));
      })
      .catch((err) => {});
    thunkAPI.dispatch(setLoadingQRCodes(false));
  },
);

const slice = createSlice({
  name: 'qrcode',
  initialState,
  reducers: {
    setLoadingQRCodes(state, action: PayloadAction<boolean>) {
      state.loadingQRCode = action.payload;
    },
    setQRCodes(state, action: PayloadAction<TQRCode>) {
      state.qrcodes = state.qrcodes.map((qrcode) =>
        qrcode.id === action.payload.id ? action.payload : qrcode,
      );
    },
    setCsv(state, action: PayloadAction<string>) {
      state.csvData = action.payload;
    },
    deleteQRCode(state, action: PayloadAction<string>) {
      state.qrcodes = state.qrcodes.filter((qrcode) => qrcode.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQRCodes.pending, (state: QRCodeState) => {
      state.loadingQRCode = true;
    });
    builder.addCase(getQRCodes.rejected, (state: QRCodeState) => {
      state.loadingQRCode = false;
    });
    builder.addCase(
      getQRCodes.fulfilled,
      (state: QRCodeState, action: PayloadAction<TResponsePagination<TQRCode[]>>) => {
        const { results, ...pagination } = action.payload;
        state.loadingQRCode = false;
        state.qrcodes = action.payload.results;
        state.pagination = pagination;
      },
    );
  },
});

export const { actions, reducer: qrcodeReducer } = slice;
export const { setLoadingQRCodes, setQRCodes, setCsv, deleteQRCode } = actions;
export default qrcodeReducer;
