import React from 'react';
import s from './style.module.scss';
import GoogleOutlined from '@ant-design/icons';

const HeaderAdmin = () => {
  return (
    <div className={s.container}>
      <div className={s.header}>
        <span>管理ダッシュボード</span>
      </div>
    </div>
  );
};

export default HeaderAdmin;
