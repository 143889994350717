/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import authReducer from 'app/pages/AuthPage/LoginPage/slice/authSlice';

import { InjectedReducersType } from 'utils/types/injector-typings';
import messageReducer from './slice/messageSlice';
import productReducer from './slice/productSlice';
import qrcodeReducer from './slice/qrcodeSlice';
import exProductReducer from './slice/exchangeProductSlice';
import dashboardReducer from './slice/dashboardSlice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export const rootReducers = {
  auth: authReducer,
  dashboard: dashboardReducer,
  message: messageReducer,
  product: productReducer,
  qrcode: qrcodeReducer,
  exProduct: exProductReducer,
};

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return (state: any) => state;
  } else {
    return combineReducers({
      ...injectedReducers,
    });
  }
}
